/** @format */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.scss";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import Home from "./Home";

import Header from "./layouts/Header1";
import Footer from "./layouts/Footer";
import Timer1 from "./utils/Timer1";
import ReactGA from 'react-ga';
ReactGA.initialize('G-7BY5JMJ227');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const chains = [bsc];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <div className='z-[9999] z-2  postion-relative'>
          <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
            themeMode='light'
            themeVariables={{
              "--w3m-background": "#1568FFca",
              "--w3m-font-family": "Nunito",
              "--w3m-background-color": "#1568FF",
              "--w3m-text-primary": "#1568FF",
            }}
            defaultChain={56}
          />

          <Header />
          {/* <div className='   sm:absolute top-0 sm:sm:left-[32%] sm:translate-x-2 flex justify-center items-center flex-col'>
            <Timer1 />
          </div> */}
          <div className='App'>
            <div className='section-heading'>
              <div className='accent-large'>Stake</div>
              <h2 className='sec-title'>Stake any token 1500% APY</h2>
            </div>
            <Home />
          </div>
          <Footer />
        </div>{" "}
      </WagmiConfig>
    </>
  );
}

export default App;
