/** @format */

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import { BigNumber } from "ethers";
import { useSnackbar } from "notistack";
import { Erc20, JIT } from "@abis/types";
import { tokenAllowanceAtomFamily } from "@atoms/balance";
import { Token } from "@utils/constants";
import {
  useJIT,
  useLP,
  useMeowBnbLP,
  useMeowBusdPL,
  useDAI,
  useCAKE,
  useBABYDOGE,
  usePINK,
} from "./useContract";
import { useAccount } from "wagmi";

export const useTokenAllowance = (token: TokenEnum, spenderAddress: string) => {
  const { address } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [allowance, setAllowance] = useAtom(tokenAllowanceAtomFamily(token));

  const JITContract = useJIT(true);
  const lpContract = useLP(true);
  const lpContractMeowBnb = useMeowBnbLP(true);
  const lpContractMeowBusd = useMeowBusdPL(true);
  const JITContractDAI = useDAI(true);
  const JITContractCAKE = useCAKE(true);
  const JITContractBABYDOGE = useBABYDOGE(true);
  const JITContractPINK = usePINK(true);

  const tokenContract: Erc20 | JIT | null = useMemo(() => {
    switch (token) {
      case Token.JIT:
        return JITContract;
      case Token.JIT_BNB:
        return lpContract;
      case Token.JIT_USDC:
        return lpContractMeowBnb;
      case Token.JIT_USDT:
        return lpContractMeowBusd;

      case Token.JIT_DAI:
        return JITContractDAI;
      case Token.JIT_CAKE:
        return JITContractCAKE;
      case Token.JIT_BABYDOGE:
        return JITContractBABYDOGE;
      case Token.JIT_PINK:
        return JITContractPINK;

      default:
        break;
    }
    return null;
  }, [
    token,
    JITContract,
    lpContract,
    lpContractMeowBnb,
    lpContractMeowBusd,
    JITContractDAI,
    JITContractCAKE,
    JITContractBABYDOGE,
    JITContractPINK,
  ]);

  const fetchAllowance = useCallback(async () => {
    if (address && tokenContract) {
      setLoading(true);
      const allowance = await tokenContract.allowance(address, spenderAddress);
      console.log("allowance", token, allowance.toString());
      setAllowance(allowance);
      setLoading(false);
    }
  }, [address, tokenContract, setAllowance, spenderAddress, token]);

  const handleApprove = useCallback(async () => {
    if (address && tokenContract) {
      setLoading(true);
      try {
        const total = await tokenContract.totalSupply();
        const tx = await tokenContract.approve(spenderAddress, total);
        enqueueSnackbar(
          <>
            Approval request has been sent to blockchain,
            <br />
            waiting for confirmation...
          </>,
          { variant: "info" },
        );
        const res = await tx.wait(1);
        console.log("approve", res);
        setAllowance(total);
      } catch (e) {
        enqueueSnackbar("Operation failed"!, {
          variant: "error",
        });
      }
      setLoading(false);
    }
  }, [address, tokenContract, spenderAddress, enqueueSnackbar, setAllowance]);

  const checkApprove = useCallback(
    async (amount: BigNumber) => {
      if (amount.lte(allowance)) {
        console.log("already approved", allowance.toString());
        return;
      }

      handleApprove();
    },
    [allowance, handleApprove],
  );

  useEffect(() => {
    fetchAllowance();
  }, [fetchAllowance]);

  return {
    loading,
    needApprove: allowance.lte(0),
    allowance,
    handleApprove,
    checkApprove,
  };
};
