/** @format */

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAtomValue } from "jotai/utils";
import { cardContent } from "@utils/theme/common";
import { Token, TokenShortName } from "@utils/constants";
import { getFullDisplayBalance, formatNumber } from "@utils/formatters";
import { getApy } from "@utils/apy";
import { tokenStatAtomFamily } from "@atoms/stat";
import { useMemo } from "react";
import { tokenPriceAtomFamily } from "@/atoms/price";

const useStyles = makeStyles({
  root: {
    padding: cardContent.padding,
  },
  item: {
    padding: "4px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    color: "#000",
    fontWeight: 600,
  },
  apy: {
    fontSize: 14,
    fontWeight: 500,
    color: "#000",
  },
  total: {
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#000",
    fontWeight: 600,
  },
});

export default function Stats({ token }) {
  const classes = useStyles();

  const JITPrice = useAtomValue(tokenPriceAtomFamily(Token.JIT));
  const stakeTokenPrice = useAtomValue(tokenPriceAtomFamily(token));
  const { total, rewardsPerBlock, isRoundActive } = useAtomValue(
    tokenStatAtomFamily(token),
  );

  const apy = useMemo(() => {
    // console.log('isRoundActive',isRoundActive)
    if (!isRoundActive) {
      return 0;
    }

    if (token === Token.JIT) {
      return getApy(1, 1, total, rewardsPerBlock);
    }

    return getApy(stakeTokenPrice, JITPrice, total, rewardsPerBlock);
  }, [isRoundActive, token, stakeTokenPrice, JITPrice, total, rewardsPerBlock]);

  return (
    <Box className={classes.root}>
      <div className={classes.item}>
        <h3 className='text-sm mt-3 font-bold leading-tight   font-head'>
          DPY
        </h3>
        <h3 className='text-xs  mt-3  font-bold leading-tight   font-head'>
          {/* {apy ? `${formatNumber(apy/365)} %` : "232.1 %"} */}
          {Token.JIT === "Meow" && apy
            ? `4.10%`
            : token === Token.JIT_BNB
            ? "3.28%"
            : token === Token.JIT_USDC
            ? "2.32%"
            : token === Token.JIT_USDT
            ? "2.19%"
            : token === Token.JIT_DAI
            ? "2.73%"
            : token === Token.JIT_CAKE
            ? "1.64%"
            : token === Token.JIT_BABYDOGE
            ? "1.91%"
            : token === Token.JIT_PINK
            ? "2.19%"
            : `4.10%`}
        </h3>
      </div>
      <div className={classes.item}>
        <h3 className='text-sm mt-3 font-bold leading-tight font-head'>APY</h3>
        <h3 className='text-xs  mt-3  font-bold leading-tight   font-head'>
          {/* {apy ? `${formatNumber(apy)} %` :"847,944 %"} */}
          {Token.JIT === "Meow" && apy
            ? `1500%`
            : token === Token.JIT_BNB
            ? "1200%"
            : token === Token.JIT_USDC
            ? "850%"
            : token === Token.JIT_USDT
            ? "800%"
            : token === Token.JIT_DAI
            ? "1000%"
            : token === Token.JIT_CAKE
            ? "600%"
            : token === Token.JIT_BABYDOGE
            ? "700%"
            : token === Token.JIT_PINK
            ? "800%"
            : `1500%`}{" "}
        </h3>
      </div>
      <div className={classes.item}>
        <h3 className='text-sm mt-3 font-bold leading-tight   font-head'>
          Total Staked
        </h3>
        <h3 className='text-xs  mt-3  font-bold leading-tight   font-head'>
          {/* {`{getFullDisplayBalance(total)} ${TokenShortName[token]}`} */}

          {Token.JIT !== "Meow"
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token === Token.JIT_BNB
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token === Token.JIT_USDC
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token === Token.JIT_USDT
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token === Token.JIT_DAI
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token === Token.JIT_CAKE
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token === Token.JIT_BABYDOGE
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token === Token.JIT_PINK
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : `${getFullDisplayBalance(total)} ${TokenShortName[token]}`}
        </h3>
      </div>
    </Box>
  );
}
