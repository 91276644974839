/** @format */

import { useCallback, useState, useMemo, useRef } from "react";
import { BigNumber, constants, utils } from "ethers";
import {
  Box,
  Button,
  Collapse,
  Typography,
  InputBase,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useAtomValue } from "jotai/utils";
import { DEPRECATED_TOKENS, StakingAddresses, Action } from "@utils/constants";
import { cardContent } from "@utils/theme/common";
import NumberFormat from "react-number-format";

import useFetchState from "@hooks/useFetchState";
import { useFetchStat } from "@hooks/useFetchStats";
import { useTokenAllowance } from "@hooks/useAllowance";
import useStaking from "@hooks/useStaking";
import PrimaryButton from "@components/PrimaryButton";
import { useNetwork, useSwitchNetwork } from "wagmi";
import {
  availableAtomFamily,
  stakedAtomFamily,
  withdrawableAtomFamily,
} from "@atoms/balance";
import { tokenStatAtomFamily } from "@atoms/stat";
import { TokenName } from "@utils/constants";
import { getFullDisplayBalance } from "@utils/formatters";
import AmountInput from "./AmountInput";
import prettyMilliseconds from "pretty-ms";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    ...cardContent,
    minHeight: 54,
  },
  amountBox: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
  },
  staked: {
    flex: 1,
    overflow: "hidden",
    color: "#fff",
  },
  buttons: {
    flex: "0 0 120px",
    display: "flex",
    justifyContent: "flex-end",
    color: palette.text.primary,
  },
  inputBox: {
    position: "relative",
    padding: cardContent.padding,
    color: "#fff",
  },
  inputBox1: {
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
    border: `1px dashed #141414`,
    overflow: "hidden",
  },
  token: {
    padding: 12,
    display: " inline-flex",
    alignItems: "center",
  },
  logo: {
    height: 32,
  },
  input: {
    flex: 1,
    padding: "10px 12px",
    borderLeft: `1px dashed #141414`,
    fontSize: 24,
    color: "rgb(107, 114, 128)",
  },

  maxLabelBox: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
    color: "#e730a8",
  },

  maxLabel: {
    fontSize: 10,
    fontWeight: 700,
    color: "grey",
  },
}));

const InputButton = withStyles(({ palette }) => ({
  root: {
    width: 50,
    minWidth: 50,
    fontWeight: 700,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
    color: "#000",
  },
}))(Button);

const Actions = [
  {
    key: Action.Stake,
    label: "+",
    disabled: false,
  },
  // {
  //   key: Action.Unstake,
  //   label: "-",
  //   disabled: false,
  // },
];

export default function AmountStake({ token, logo, disabledActions }) {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [currentAction, setCurrentAction] = useState();
  const [input, setInput] = useState();
  const [isMax, setIsMax] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const actions = useMemo(() => {
    if (disabledActions) {
      return Actions.map(({ key, ...item }) => ({
        ...item,
        key,
        disabled: disabledActions.indexOf(key) > -1,
      }));
    }
    return Actions;
  }, [disabledActions]);

  const refreshState = useFetchState();
  const refreshStat = useFetchStat(token);

  const refresh = useCallback(() => {
    refreshState();
    refreshStat();
  }, [refreshStat, refreshState]);

  const stakingContract = useStaking(token);
  const { loading, needApprove, handleApprove, checkApprove } =
    useTokenAllowance(token, StakingAddresses[token]);

  const available = useAtomValue(availableAtomFamily(token));
  const staked = useAtomValue(stakedAtomFamily(token));
  const withdrawable = useAtomValue(withdrawableAtomFamily(token));

  const { lockDownSeconds, isRoundActive } = useAtomValue(
    tokenStatAtomFamily(token),
  );

  const inputMax = useMemo(() => {
    if (currentAction === Action.Stake) {
      return available;
    } else if (currentAction === Action.Unstake) {
      return withdrawable;
    }
    return constants.Zero;
  }, [currentAction, available, withdrawable]);

  const amount = useMemo(
    () =>
      isMax
        ? inputMax
        : utils.parseUnits((input || "0").replace(/[^0-9.]/g, "")),
    [input, isMax, inputMax],
  );

  const handleInput = useCallback((val, max = false) => {
    setIsMax(max);
    setInput(val);
  }, []);

  const handleAction = useCallback(action => {
    setCurrentAction(prevAction =>
      prevAction === action ? undefined : action,
    );
    setInput("0");
  }, []);

  const resetInput = useCallback(() => {
    setInput("0");
  }, []);

  const handleStake = useCallback(async () => {
    await checkApprove(amount);
    const tx = await stakingContract.stake(amount);
    enqueueSnackbar(
      <>
        Transaction has been sent to blockchain,
        <br />
        waiting for confirmation...
      </>,
      { variant: "info" },
    );
    const res = await tx.wait(1);
    console.log("Stake:", res);
    enqueueSnackbar(
      `Successfully staked ${getFullDisplayBalance(amount)} ${token}`,
      { variant: "success" },
    );
    refresh();
    resetInput();
  }, [
    checkApprove,
    amount,
    stakingContract,
    enqueueSnackbar,
    token,
    refresh,
    resetInput,
  ]);

  const handleUnstake = useCallback(async () => {
    const tx = await stakingContract.exit();
    // console.log("Unstake:", tx);
    enqueueSnackbar(
      <>
        Transaction has been sent to blockchain,
        <br />
        waiting for confirmation...
      </>,
      { variant: "info" },
    );
    const res = await tx.wait(1);
    enqueueSnackbar(
      `Successfully unstaked ${getFullDisplayBalance(amount)} ${token}`,
      { variant: "success" },
    );
    refresh();
    resetInput();
  }, [stakingContract, amount, enqueueSnackbar, token, refresh, resetInput]);

  const handleSubmit = useCallback(async () => {
    try {
      if (currentAction && token && stakingContract) {
        setSubmitting(true);
        // if (!isRoundActive) {
        //   await handleStake();
        //   // alert("stake");
        // } else {
        //   await handleUnstake();
        //   // alert("Un stake");
        // }
        if (currentAction === Action.Stake) {
          await handleStake();
        } else if (currentAction === Action.Unstake) {
          await handleUnstake();
        }
      }
    } catch (e) {}
    setSubmitting(false);
  }, [
    currentAction,
    token,
    stakingContract,
    amount,
    handleStake,
    handleUnstake,
    enqueueSnackbar,
  ]);
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const { btnLabel, btnDisabled } = useMemo(() => {
    const stakedNotStarted = currentAction === Action.Stake && !isRoundActive;
    return {
      btnLabel: stakedNotStarted
        ? "Not Started"
        : currentAction
        ? Action?.[currentAction]
        : "",
      // btnLabel: stakedNotStarted
      //   ? "Not Started"
      //   : currentAction
      //   ? "UnStake & Get Reward"
      //   : "",

      btnDisabled: stakedNotStarted,
    };
  }, [currentAction, isRoundActive]);
  const maxRef = useRef();
  const setMax = useCallback(() => {
    if (!amount.eq(inputMax)) {
      maxRef.current = true;
      handleInput(
        getFullDisplayBalance(inputMax, { mantissa: 6, trimMantissa: true }),
        true,
      );
    }
  }, [amount, inputMax, handleInput]);

  return (
    <>
      {chain?.id === 56 ? (
        <Box className={classes.root}>
          {needApprove ? (
            <PrimaryButton
              size='large'
              fullWidth
              loading={loading}
              onClick={handleApprove}>
              Approve Contract
            </PrimaryButton>
          ) : (
            <>
              <h3 className='text-sm mt-3 font-semibold leading-tight font-head'>
                {TokenName[token]} Staked
              </h3>
              <Box className={classes.amountBox}>
                <Box className={classes.staked}>
                  <h1 className='font-head text-lg leading-tight pr-3 text-black font-bold'>
                    {getFullDisplayBalance(staked)}
                  </h1>
                </Box>
                <Box className={classes.buttons}>
                  {actions.map(({ key, label, disabled }) =>
                    disabled ? (
                      <i key={key} />
                    ) : (
                      <InputButton
                        key={key}
                        disabled={disabled}
                        className='bg-gradient-to-br  from-[#1568ff] to-[#1568ff] text-white'
                        variant='contained'
                        size='small'
                        onClick={() => handleAction(key)}>
                        {label}
                      </InputButton>
                    ),
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <PrimaryButton
          size='large'
          fullWidth
          loading={!switchNetwork}
          onClick={() => switchNetwork?.("56")}>
          switch BSC {isLoading && pendingChainId === "56" && " (switching)"}
        </PrimaryButton>
      )}

      <Collapse in={!!currentAction}>
        {/* <Box className={classes.inputBox}>
          <AmountInput
            token={token}
            logo={logo}
            input={input}
            onInput={handleInput}
            amount={amount}
            max={inputMax}
            lockDownSeconds={lockDownSeconds}
            btnLabel={btnLabel}
            onSubmit={handleSubmit}
            loading={submitting}
            disabled={btnDisabled}
          />
        </Box> */}
        <Box className={classes.root}>
          <Box className={classes.inputBox1}>
            <span className={classes.token}>
              {/* {logo ? <img src={logo} alt={""} className={classes.logo} /> : null}
          <Typography>{TokenName[token]}</Typography> */}
              <Typography>{TokenName[token]}</Typography>
            </span>
            <NumberFormat
              value={input}
              onValueChange={values => {
                handleInput(values.value, maxRef.current);
                maxRef.current = false;
              }}
              allowNegative={false}
              thousandSeparator
              isNumericString
              placeholder='0.0'
              customInput={InputBase}
              className={`${classes.input} font-head `}
            />
            <button
              className=' select-none disabled:cursor-not-allowed rounded-full bg-blue-gray-500 py-2 px-2 text-center mr-3 align-middle font-para text-xs font-bold  text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
              onClick={setMax}>
              Max
            </button>
          </Box>
          <Box className={classes.maxLabelBox}>
            {/* <Typography variant='overline' style={{ color: "#455a64" }}>
              {rewardsDurationSeconds && `Lock: ${rewardsDurationSeconds}`}
            </Typography> */}
            <Typography
              variant='overline'
              color={amount.gt(inputMax) ? "error" : "primary"}
              className={classes.maxLabel}>
              {getFullDisplayBalance(inputMax)} Available
            </Typography>
          </Box>
          <PrimaryButton
            size='large'
            fullWidth
            disabled={btnDisabled}
            onClick={handleSubmit}
            loading={loading}>
            {btnLabel}
          </PrimaryButton>
        </Box>
      </Collapse>
    </>
  );
}
