/** @format */

import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledButton = withStyles(({ palette }) => ({
  root: {
    flex: "0 0 120px",
    padding: "12px 22px",
    borderRadius: "120px",
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    transition: "all 350ms ease",
    WebkitTransition: "all 350ms ease",
    letterSpacing: "0.25em",
    fontSize: "0.73em",
    background: "#1568ff",
    "&:disabled": {
      color: "#000",
      background: "#E0E0E0",
      opacity: "0.8",
      boxShadow: "none",
    },
    "&:hover": {
      background: "#fd6d6f",
      boxShadow: "6px 6px 13px 0 rgba(0, 0, 0, 0.18)",
      transform: "translate(0px, -8px)",
      MsTransform: "translate(0px, -8px)",
      WebkitTransform: "translate(0px, -8px)",
    },
  },
}))(Button);

export default function PrimaryButton({
  loading = false,
  disabled,
  children,
  ...props
}) {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {loading ? (
        <CircularProgress color='inherit' size={20} thickness={2} />
      ) : (
        children
      )}
    </StyledButton>
  );
}
