/** @format */

import { useWeb3Modal } from "@web3modal/react";
import { Button, ButtonProps } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const isAvailable = true;
const StyledButton = withStyles(({ palette }) => ({
  root: {
    // fontWeight: 700,
    padding: "12px 22px",
    borderRadius: 16,
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    transition: "all 350ms ease",
    WebkitTransition: "all 350ms ease",
    letterSpacing: "0.25em",
    fontSize: "0.73em",
  },
}))(Button);

const useStyles = makeStyles({
  rounded: {
    borderRadius: "120px",
    background: "#1568ff",
    "&:disabled": {
      color: "#000",
      background: "#E0E0E0",
      opacity: "0.8",
      boxShadow: "none",
      borderRadius: "120px",
    },
    "&:hover": {
      background: "#fd6d6f",
      boxShadow: "6px 6px 13px 0 rgba(0, 0, 0, 0.18)",
      transform: "translate(0px, -8px)",
      MsTransform: "translate(0px, -8px)",
      WebkitTransform: "translate(0px, -8px)",
    },
  },
});
export default function ConnectButton({ rounded, ...props }) {
  const { open } = useWeb3Modal();
  const classes = useStyles();
  return (
    // <button
    //   onClick={() => open()}
    //   disabled={!isAvailable}
    //   className='flex mt-10 w-full btn border-0 fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
    //   {isAvailable ? (
    //     <div className='flex items-center justify-center'>
    //       <svg
    //         xmlns='http://www.w3.org/2000/svg'
    //         width={16}
    //         height={16}
    //         fill='currentColor'
    //         className='bi bi-wallet'
    //         viewBox='0 0 16 16'>
    //         <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1' />
    //       </svg>
    //       &nbsp; Connect Wallet
    //     </div>
    //   ) : (
    //     "Live Soon"
    //   )}
    // </button>
    <StyledButton
      variant='contained'
      color='primary'
      size='small'
      onClick={() => open()}
      disabled={!isAvailable}
      {...props}
      className={rounded ? classes.rounded : ""}>
      {isAvailable ? "Connect Wallet" : "Available Soon"}
    </StyledButton>
  );
}
