/** @format */

import { withStyles } from "@material-ui/core/styles";
import { Backdrop, Box, CardContent, Typography } from "@material-ui/core";
import { useAtomValue } from "jotai/utils";
import { stakedAtomFamily, withdrawableAtomFamily } from "@atoms/balance";
import defaultTheme from "@utils/theme";
import ConnectButton from "../ConnectButton";
import CardSection from "./CardSection";
import Pending from "./Pending";
import Stats from "./Stats";
import Earned from "./Earned";
import AmountStake from "./AmountStake";
import { useMemo } from "react";
import { useAccount } from "wagmi";

const StyledContent = withStyles(() => ({
  root: {
    padding: 0,
    backgroundColor: "transparent",
  },
}))(CardContent);
const StyledBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    fontSize: 22,
    textAlign: "center",
    backgroundColor: "#a3a3aef1",
    width: "100%",
    margin: 0,
    borderRadius: 20,
  },
})(Backdrop);
export default function StakeCard({
  token,
  bg,
  color = defaultTheme.palette.primary.main,
  cardTitle,
  desc,
  logo,
  links,
  open = true,
  disabledActions,
  para,
  pendingText,
  ...props
}) {
  const { isConnected } = useAccount();
  const staked = useAtomValue(stakedAtomFamily(token));
  const withdrawable = useAtomValue(withdrawableAtomFamily(token));

  const cardEnabled = useMemo(() => {
    return true;
  }, [staked, token, withdrawable]);

  if (!cardEnabled) {
    return null;
  }

  return (
    <div className='main_card relative overflow-hidden'>
      <div class='absolute left-0 top-0 '>
        <div class='absolute transform -rotate-45 bg-blue-600 text-xs py-1 text-center text-white font-semibold  left-[-48px] top-[22px] w-[170px]'>
          {desc}
        </div>
      </div>
      <h3 className='mb-8 font-head tracking-widest uppercase font-semibold text-purple-800 text-center text-2xl'>
        {cardTitle}
      </h3>
      <p className='text-gray-700 text-sm leading-6 text-center font-para'>
        {para}
      </p>
      {/* <div className='ref m-auto my-3 font-para'>{desc}</div> */}
      <StyledContent>
        <Stats token={token} />
      </StyledContent>
      <div
        style={{
          position: open ? undefined : "relative",
        }}>
        <StyledContent>
          <Earned token={token} />
          {isConnected ? (
            <AmountStake
              logo={logo}
              token={token}
              disabledActions={disabledActions}
            />
          ) : (
            <CardSection>
              <ConnectButton fullWidth rounded size='large' />
            </CardSection>
          )}
        </StyledContent>

        {!open && (
          <StyledBackdrop open>
            <Box>
              <Typography variant='h6' display='block'>
                {pendingText}
              </Typography>
            </Box>
          </StyledBackdrop>
        )}
      </div>
    </div>
  );
}
