/** @format */

import React, { useEffect } from "react";

function Header() {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".navbar");
      if (window.pageYOffset > 85) {
        header.classList.add("navbar-fixed-top");
      } else {
        header.classList.remove("navbar-fixed-top");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <nav className='navbar navbar-expand-lg '>
        <div className='container-fluid flex-nowrap'>
          <a
            className='navbar-brand'
            href='https://dingdang.io/'
            rel='noreferrer'
            target='_blank'>
            <img src={"assets/logo-cr.png"} alt='' width={80} />
          </a>
          {/* <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button> */}
          {/* <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav m-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a
                  className='nav-link active '
                  target='_blank'
                  rel='noreferrer'
                  href=''>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link '
                  target='_blank'
                  rel='noreferrer'
                  href='/'>
                  Contract
                </a>
              </li>
              <li>
                <a
                  className='nav-link'
                  target='_blank'
                  rel='noreferrer'
                  href=''>
                  Price Chart
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className='nav-link'
                  href=''
                  rel='noreferrer'
                  target='_blank'>
                  Dextool
                </a>
              </li>
            </ul>
          </div> */}
          <ul className='navbar-nav ms-auto mb-2 mb-lg-0 gap-2 flex-row items-center'>
            <li className='nav-item'>
              <a rel='noreferrer' target='_blank' href='https://dingdang.io/'>
                <button className='nav-btns'>Home </button>
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='https://pancakeswap.finance/swap?outputCurrency=0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e'
                target='_blank'
                rel='noopener noreferrer'>
                <button className='nav-btns'> Get $Meow </button>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
