/** @format */

import { useCallback } from "react";
import { useUpdateAtom } from "jotai/utils";
import { BigNumber, constants } from "ethers";
import { tokenStatAtomFamily } from "@atoms/stat";
import { BSC_BLOCK_TIME, Token } from "@/utils/constants";
import { useRpcStaking } from "./useStaking";
import useFetchPrice from "./useFetchPrice";

export function useFetchStat(token: TokenEnum) {
  const stakingContract = useRpcStaking(token);

  // stat
  const setStat = useUpdateAtom(tokenStatAtomFamily(token));

  const fetchData = useCallback(async () => {
    let res: BigNumber[] = [];
    if (stakingContract) {
      res = await Promise.all([
        stakingContract.totalSupply(), // total staked
        stakingContract.periodFinish(), // finish time
        // stakingContract.rewardRate(), // rewards per second
        stakingContract.rewardsDuration(), // rewardDuration in seconds
        // stakingContract.lockDownDuration(), // lockDownDuration in seconds
      ]);
    }

    const [
      totalStaked = constants.Zero,
      periodFinish = constants.Zero,
      rewardsPerSecond = constants.Zero,
      rewardsDurationSeconds = constants.Zero,
      lockDownSeconds = constants.Zero,
    ] = res;
    console.log("total ===>?", totalStaked.toString());
    const finishTimestamp = periodFinish.toNumber();
    const now = Date.now() / 1000;
    // console.log('res', now, finishTimestamp, periodFinish.toNumber());

    setStat({
      isRoundActive: finishTimestamp > 0 && now < finishTimestamp,
      total: totalStaked,

      rewardsPerBlock: rewardsPerSecond.mul(BSC_BLOCK_TIME),
      rewardsDurationSeconds,
      lockDownSeconds,
    });

    return constants.Zero;
  }, [setStat, stakingContract]);

  return fetchData;
}

export default function useFetchStats() {
  const JIT = useFetchStat(Token.JIT);
  const lp = useFetchStat(Token.JIT_BNB);
  const lpContractMeowBnb = useFetchStat(Token.JIT_USDC);
  const lpContractMeowBusd = useFetchStat(Token.JIT_USDT);
  const lpDAI = useFetchStat(Token.JIT_DAI);
  const lpCAKE = useFetchStat(Token.JIT_CAKE);
  const lpBABYDOGE = useFetchStat(Token.JIT_BABYDOGE);
  const lpPINK = useFetchStat(Token.JIT_PINK);

  // price
  const fetchPrice = useFetchPrice();

  const fetch = useCallback(
    () =>
      Promise.all([
        fetchPrice(),

        JIT(),
        lp(),
        lpContractMeowBnb(),
        lpContractMeowBusd(),
        lpDAI(),
        lpCAKE(),
        lpBABYDOGE(),
        lpPINK(),
      ]),
    [
      fetchPrice,
      JIT,
      lp,
      lpContractMeowBnb,
      lpContractMeowBusd,
      lpDAI,
      lpCAKE,
      lpBABYDOGE,
      lpPINK,
    ],
  );

  return fetch;
}
