/** @format */

import { useCallback } from "react";
import { constants } from "ethers";
import { useUpdateAtom } from "jotai/utils";
import { useSnackbar } from "notistack";
import { loadingAllAtom } from "@atoms/loading";
import { availableAtomFamily } from "@atoms/balance";
import { Token } from "@utils/constants";
import {
  useJIT,
  useLP,
  useMeowBnbLP,
  useMeowBusdPL,
  useDAI,
  useCAKE,
  useBABYDOGE,
  usePINK,
} from "./useContract";
import { useAccount } from "wagmi";
import useFetchStakingData from "./useFetchStakingData";

export default function useFetchState() {
  const { address } = useAccount();

  const { enqueueSnackbar } = useSnackbar();

  // token available

  const JITToken = useJIT();
  const lpToken = useLP();
  const lpContractMeowBnb = useMeowBnbLP();
  const lpContractMeowBusd = useMeowBusdPL();
  const JITContractDAI = useDAI(true);
  const JITContractCAKE = useCAKE(true);
  const JITContractBABYDOGE = useBABYDOGE(true);
  const JITContractPINK = usePINK(true);

  // all loading
  const setLoading = useUpdateAtom(loadingAllAtom);

  // available atoms

  const setAvailableJIT = useUpdateAtom(availableAtomFamily(Token.JIT));
  const setAvailableLP = useUpdateAtom(availableAtomFamily(Token.JIT_BNB));
  const setAvailableLP1 = useUpdateAtom(availableAtomFamily(Token.JIT_USDC));
  const setAvailableLP2 = useUpdateAtom(availableAtomFamily(Token.JIT_USDT));

  const setAvailableLP3 = useUpdateAtom(availableAtomFamily(Token.JIT_DAI));
  const setAvailableLP4 = useUpdateAtom(availableAtomFamily(Token.JIT_CAKE));
  const setAvailableLP5 = useUpdateAtom(
    availableAtomFamily(Token.JIT_BABYDOGE),
  );
  const setAvailableLP6 = useUpdateAtom(availableAtomFamily(Token.JIT_PINK));

  // fetch token staking data
  const fetchJITStakingData = useFetchStakingData(Token.JIT);
  const fetchLPStakingData = useFetchStakingData(Token.JIT_BNB);
  const fetchLPStakingData1 = useFetchStakingData(Token.JIT_USDC);
  const fetchLPStakingData2 = useFetchStakingData(Token.JIT_USDT);

  const fetchLPStakingData3 = useFetchStakingData(Token.JIT_DAI);
  const fetchLPStakingData4 = useFetchStakingData(Token.JIT_CAKE);
  const fetchLPStakingData5 = useFetchStakingData(Token.JIT_BABYDOGE);
  const fetchLPStakingData6 = useFetchStakingData(Token.JIT_PINK);

  const fetchBalances = useCallback(async () => {
    // console.log("address 6", fetchLPStakingData6);

    try {
      setLoading(true);
      const [JIT, lp, lp1, lp2, lp3, lp4, lp5, lp6] = await Promise.all([
        address && JITToken ? JITToken.balanceOf(address) : constants.Zero,
        address && lpToken ? lpToken.balanceOf(address) : constants.Zero,
        address && lpContractMeowBnb
          ? lpContractMeowBnb.balanceOf(address)
          : constants.Zero,
        address && lpContractMeowBusd
          ? lpContractMeowBusd.balanceOf(address)
          : constants.Zero,

        address && JITContractDAI
          ? JITContractDAI.balanceOf(address)
          : constants.Zero,

        address && JITContractCAKE
          ? JITContractCAKE.balanceOf(address)
          : constants.Zero,

        address && JITContractBABYDOGE
          ? JITContractBABYDOGE.balanceOf(address)
          : constants.Zero,

        address && JITContractPINK
          ? JITContractPINK.balanceOf(address)
          : constants.Zero,

        fetchJITStakingData(),
        fetchLPStakingData(),
        fetchLPStakingData1(),
        fetchLPStakingData2(),
        fetchLPStakingData3(),
        fetchLPStakingData4(),
        fetchLPStakingData5(),
        fetchLPStakingData6(),
      ]);

      setAvailableJIT(JIT);
      setAvailableLP(lp);
      setAvailableLP1(lp1);
      setAvailableLP2(lp2);
      setAvailableLP3(lp3);
      setAvailableLP4(lp4);
      setAvailableLP5(lp5);
      setAvailableLP6(lp6);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to loading balances", { variant: "error" });
    }
    setLoading(false);
  }, [
    setLoading,
    address,
    JITToken,
    lpToken,
    lpContractMeowBnb,
    lpContractMeowBusd,
    JITContractDAI,
    JITContractCAKE,
    JITContractBABYDOGE,
    JITContractPINK,

    fetchJITStakingData,
    fetchLPStakingData,
    fetchLPStakingData1,
    fetchLPStakingData2,
    fetchLPStakingData3,
    fetchLPStakingData4,
    fetchLPStakingData5,
    fetchLPStakingData6,

    setAvailableJIT,
    setAvailableLP,
    setAvailableLP1,
    setAvailableLP2,
    setAvailableLP3,
    setAvailableLP4,
    setAvailableLP5,
    setAvailableLP6,

    enqueueSnackbar,
  ]);

  return fetchBalances;
}
