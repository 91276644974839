/** @format */

import { useCallback, useState, useEffect } from "react";
import { BigNumber, constants, utils } from "ethers";
import { useUpdateAtom } from "jotai/utils";
import { fetchPrice } from "@/apis/coingecko";
import { fetchTotalLiquidity } from "../apis/pancakeswap";
import { tokenPriceAtomFamily } from "@atoms/price";
import erc20Abi from "@abis/erc20.json";
import { Erc20 } from "@abis/types";
import { TokenAddresses, Token } from "@utils/constants";
import { useRpcContract } from "./useContract";

const lpDisabled = false;

export default function useFetchPrice() {
  const [timestamp, setTimestamp] = useState(0);

  const lpToken = useRpcContract(TokenAddresses[Token.JIT_BNB], erc20Abi);
  const lpToken1 = useRpcContract(TokenAddresses[Token.JIT_USDC], erc20Abi);
  const lpToken2 = useRpcContract(TokenAddresses[Token.JIT_USDT], erc20Abi);
  const lpToken3 = useRpcContract(TokenAddresses[Token.JIT_DAI], erc20Abi);
  const lpToken4 = useRpcContract(TokenAddresses[Token.JIT_CAKE], erc20Abi);
  const lpToken5 = useRpcContract(TokenAddresses[Token.JIT_BABYDOGE], erc20Abi);
  const lpToken6 = useRpcContract(TokenAddresses[Token.JIT_PINK], erc20Abi);

  const setJITPrice = useUpdateAtom(tokenPriceAtomFamily(Token.JIT));
  const setLpPrice = useUpdateAtom(tokenPriceAtomFamily(Token.JIT_BNB));
  const setLpPrice1 = useUpdateAtom(tokenPriceAtomFamily(Token.JIT_USDC));
  const setLpPrice2 = useUpdateAtom(tokenPriceAtomFamily(Token.JIT_USDT));
  const setLpPrice3 = useUpdateAtom(tokenPriceAtomFamily(Token.JIT_DAI));
  const setLpPrice4 = useUpdateAtom(tokenPriceAtomFamily(Token.JIT_CAKE));
  const setLpPrice5 = useUpdateAtom(tokenPriceAtomFamily(Token.JIT_BABYDOGE));
  const setLpPrice6 = useUpdateAtom(tokenPriceAtomFamily(Token.JIT_PINK));

  const run = useCallback(async () => {
    const now = Date.now() / 1000;

    if (now - timestamp < 5) {
      return;
    }
    setTimestamp(now);

    const [
      { JIT },
      totalLiquidityPCS,
      lpTotalSupplyPCS,
      totalLiquidityPSBnb,
      lpTotalSupplyPSbnb,
      totalLiquidityPSBusd,
      lpTotalSupplyPsBusd,
      totalLiquidityPSDAI,
      lpTotalSupplyPsDAI,
      totalLiquidityPSCAKE,
      lpTotalSupplyPsCAKE,
      totalLiquidityPSBABYDOGE,
      lpTotalSupplyPsBABYDOGE,
      totalLiquidityPSPINK,
      lpTotalSupplyPsPINK,
    ] = await Promise.all([
      fetchPrice(),
      lpDisabled
        ? 0
        : fetchTotalLiquidity(
            "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            "0xe55072ab00eeccd4ed5b581849e578fd54585ff8",
          ),
      !lpDisabled && lpToken ? lpToken.totalSupply() : constants.Zero,
      lpDisabled
        ? 0
        : fetchTotalLiquidity(
            "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            "0x651475bb871b2668f91d3d58cb815efa030e518b",
          ),
      !lpDisabled && lpToken1 ? lpToken1.totalSupply() : constants.Zero,
      lpDisabled
        ? 0
        : fetchTotalLiquidity(
            "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            "0x65723b6b0b808d9f2bD7ED6232a814b4919D1923",
          ),
      !lpDisabled && lpToken2 ? lpToken2.totalSupply() : constants.Zero,
      !lpDisabled && lpToken3 ? lpToken3.totalSupply() : constants.Zero,
      lpDisabled
        ? 0
        : fetchTotalLiquidity(
            "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            "0x651475bb871b2668f91d3d58cb815efa030e518b",
          ),
      !lpDisabled && lpToken4 ? lpToken4.totalSupply() : constants.Zero,
      lpDisabled
        ? 0
        : fetchTotalLiquidity(
            "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            "0xe55072ab00eeccd4ed5b581849e578fd54585ff8",
          ),
      !lpDisabled && lpToken5 ? lpToken5.totalSupply() : constants.Zero,
      !lpDisabled && lpToken6 ? lpToken6.totalSupply() : constants.Zero,
    ]);

    const calculateLpPrice = (totalLiquidity, lpTotalSupply) =>
      lpTotalSupply && lpTotalSupply.gt(0)
        ? utils.parseEther(totalLiquidity.toString()).div(lpTotalSupply)
        : BigNumber.from(0);

    setJITPrice(JIT);
    setLpPrice(calculateLpPrice(totalLiquidityPCS, lpTotalSupplyPCS));
    setLpPrice1(calculateLpPrice(totalLiquidityPSBnb, lpTotalSupplyPSbnb));
    setLpPrice2(calculateLpPrice(totalLiquidityPSBusd, lpTotalSupplyPsBusd));
    setLpPrice3(calculateLpPrice(totalLiquidityPSDAI, lpTotalSupplyPsDAI));
    setLpPrice4(calculateLpPrice(totalLiquidityPSCAKE, lpTotalSupplyPsCAKE));
    setLpPrice5(
      calculateLpPrice(totalLiquidityPSBABYDOGE, lpTotalSupplyPsBABYDOGE),
    );
    setLpPrice6(calculateLpPrice(totalLiquidityPSPINK, lpTotalSupplyPsPINK));
  }, [
    lpToken,
    lpToken1,
    lpToken2,
    lpToken3,
    lpToken4,
    lpToken5,
    lpToken6,
    setJITPrice,
    setLpPrice,
    setLpPrice1,
    setLpPrice2,
    setLpPrice3,
    setLpPrice4,
    setLpPrice5,
    setLpPrice6,
    timestamp,
  ]);

  useEffect(() => {
    run();
  }, [run]);

  return run;
}
