/** @format */

import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    // type: "dark",
  },
});

export default theme;
