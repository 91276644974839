import fetch from "cross-fetch";

// const ENDPOINT = "https://api.pancakeswap.info/api/v2/pairs/";
const API = "https://api.pancakeswap.finance/lpInfo"


const jitAddress = "0x652000ba3c230d83279AEC84A49d41d706AFB0F1";

export async function fetchTotalLiquidity(address1, address2) {
  const url = `${API}/${address1}/${jitAddress}/${address2}?${Math.random()}`

  try {
    const res = await fetch(url);
    const jsonData = await res.json();
    // console.log(address2, parseFloat(jsonData.liquidity))
    return parseFloat(jsonData.liquidity);
  } catch (error) {
    // console.log(error);
    return 0;
  }
}
