/** @format */

import { BigNumber } from "ethers";

export enum Action {
  Stake = 1,
  Unstake,
}

export enum Token {
  JIT = "Meow",
  JIT_BNB = "BNB",
  JIT_USDC = "USDC",
  JIT_USDT = "USDT",
  JIT_DAI = "DAI",
  JIT_CAKE = "CAKE",
  JIT_BABYDOGE = "BABYDOGE",
  JIT_PINK = "PINK",
}

export const TokenName = {
  [Token.JIT]: "Meow",
  [Token.JIT_BNB]: "BNB",
  [Token.JIT_USDC]: "USDC",
  [Token.JIT_USDT]: "USDT",
  [Token.JIT_DAI]: "DAI",
  [Token.JIT_CAKE]: "CAKE",
  [Token.JIT_BABYDOGE]: "BABYDOGE",
  [Token.JIT_PINK]: "PINK",
};

export const TokenShortName = {
  [Token.JIT]: "Meow",
  [Token.JIT_BNB]: "BNB",
  [Token.JIT_USDC]: "USDC",
  [Token.JIT_USDT]: "USDT",
  [Token.JIT_DAI]: "DAI",
  [Token.JIT_CAKE]: "CAKE",
  [Token.JIT_BABYDOGE]: "BABYDOGE",
  [Token.JIT_PINK]: "PINK",
};

declare global {
  type ActionEnum = Action;
  type TokenEnum = Token;
}

export const CHAIN_NAME_MAP: {
  [chain: number]: string;
} = {
  56: "BSC Mainnet",
  97: "BSC Testnet",
};

export const TOKEN_ADDRESS: {
  [chain: number]: {
    [t in Token]: string;
  };
} = {
  56: {
    [Token.JIT]: "0xb043F1099Fd4E28Edd3549dbf36F9e6B7CA1fF7e",
    [Token.JIT_BNB]: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    [Token.JIT_USDC]: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    [Token.JIT_USDT]: "0x55d398326f99059fF775485246999027B3197955",
    [Token.JIT_DAI]: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    [Token.JIT_CAKE]: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    [Token.JIT_BABYDOGE]: "0xc748673057861a797275CD8A068AbB95A902e8de",
    [Token.JIT_PINK]: "0x602bA546A7B06e0FC7f58fD27EB6996eCC824689",
  },
  97: {
    [Token.JIT]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_BNB]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_USDC]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_USDT]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_DAI]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_CAKE]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_BABYDOGE]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_PINK]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
  },
  1337: {
    [Token.JIT]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_BNB]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_USDC]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_USDT]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_DAI]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_CAKE]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_BABYDOGE]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_PINK]: "0x00000000000000000000000000000000000000000",
  },
};

// staking contract
export const STAKING_CONTRACT_ADDRESS: {
  [chain: number]: {
    [t in Token]: string;
  };
} = {
  56: {
    [Token.JIT]: "0x4444Cf4516a0fe17e1B53A95B506d628Ae154AaB",
    [Token.JIT_BNB]: "0xE39006c3458d3885aBa3fc33921a825FDEe90C24",
    [Token.JIT_USDC]: "0x6e6b81b52B3C9E86c6B06fBb01981d30C59a2CED",
    [Token.JIT_USDT]: "0xdD4040c9DC5437ecdF91FE4cFB714f53Fb10121D",
    [Token.JIT_DAI]: "0x88b6F528bf07127e14d42d74dd7cB296963eD5aD",
    [Token.JIT_CAKE]: "0x2Cb4C4357D092C296046c6313A346c2aed3Cb2f7",
    [Token.JIT_BABYDOGE]: "0xaE56b07B9957f04198f64995627a197A6F24554a",
    [Token.JIT_PINK]: "0xcb02C2D236E2F1b400D15D0e38195F608980c01B",
  },
  97: {
    [Token.JIT]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_BNB]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_USDC]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_USDT]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_DAI]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_CAKE]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_BABYDOGE]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_PINK]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
  },
  1337: {
    [Token.JIT]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_BNB]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_USDC]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_USDT]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_DAI]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_CAKE]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_BABYDOGE]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_PINK]: "0x00000000000000000000000000000000000000000",
  },
};

// const EnvChainId = parseInt(process.env.REACT_APP_CHAIN_ID);

export const ChainId = 56; //[56, 97].indexOf(EnvChainId) > -1 ? EnvChainId : 97;
export const ChainName = CHAIN_NAME_MAP[ChainId];
export const TokenAddresses = TOKEN_ADDRESS[ChainId];
export const StakingAddresses = STAKING_CONTRACT_ADDRESS[ChainId];

// BSC 3 seconds per block
export const BSC_BLOCK_TIME = 3;

// BSC Blocks per year
export const BLOCKS_PER_YEAR = BigNumber.from(
  (60 / BSC_BLOCK_TIME) * 60 * 24 * 365,
); // 10512000

export const DEPRECATED_TOKENS = [
  // Token.JIT_BNB_DEPRECATED,
  // Token.JIT_BNB_LEGACY,
];
