/** @format */

import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRequest from "@ahooksjs/use-request";
import StakeCard from "@components/StakeCard";
import { Token } from "@utils/constants";
import useFetchStats from "@hooks/useFetchStats";
import useFetchState from "@hooks/useFetchState";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
const useStyles = makeStyles({
  container: {
    padding: "0vh 24px 24px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    overflow: "hidden",
  },
  card: {
    margin: "20px !important",
  },
});

const cards = [
  {
    token: Token.JIT,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "Meow ~ Meow",
    desc: <>7 Days</>,
    para: "Invest $Meow to get reward in Meow",
    open: true,
    pendingText: (
      <>
        The Pool will be live once <br />
        <strong>other two staking pools filled</strong>
        <br />
        <small>No max wallet limit - 1,500,000 reward in Meow</small>
      </>
    ),
  },
  {
    token: Token.JIT_BNB,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "BNB ~ Meow",
    desc: <>7 Days</>,
    para: "Invest BNB to get reward in Meow",

    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>16:15 UTC after PCS listing!</strong>
        <br />
        <small>1000 max wallet - 1,000,000 reward in Meow</small>
      </>
    ),
  },
  {
    token: Token.JIT_USDC,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "USDC ~ Meow",
    desc: <>7 Days</>,
    para: "Invest $USDC to get reward in Meow",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in Meow</small>
      </>
    ),
  },
  {
    token: Token.JIT_USDT,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "USDT ~ Meow",
    desc: <>7 Days</>,
    para: "Invest $USDT to get reward in Meow",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in Meow</small>
      </>
    ),
  },
  {
    token: Token.JIT_DAI,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "DAI ~ Meow",
    desc: <>7 Days</>,
    para: "Invest $DAI to get reward in Meow",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in Meow</small>
      </>
    ),
  },
  {
    token: Token.JIT_CAKE,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "CAKE ~ Meow",
    desc: <>7 Days</>,
    para: "Invest $CAKE to get reward in Meow",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in Meow</small>
      </>
    ),
  },
  {
    token: Token.JIT_BABYDOGE,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "BABYDOGE ~ Meow",
    desc: <>7 Days</>,
    para: "Invest $BABYDOGE to get reward in Meow",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in Meow</small>
      </>
    ),
  },
  {
    token: Token.JIT_PINK,
    bg: "./assets/bgs/Meow.png",
    color: "#fff",
    cardTitle: "PINK ~ Meow",
    desc: <>7 Days</>,
    para: "Invest $PINK to get reward in Meow",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in Meow</small>
      </>
    ),
  },
];

export default function Home() {
  const classes = useStyles();
  const { isConnected } = useAccount();

  const fetchStats = useFetchStats();
  const fetchState = useFetchState();

  useRequest(fetchStats, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  const { run, cancel } = useRequest(fetchState, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });
  const { chain } = useNetwork();
  useEffect(() => {
    if (isConnected && chain?.id === 56) {
      run();
    } else {
      // switchNetwork?.("56");
      cancel();
    }
  }, [cancel, isConnected, run]);

  return (
    <div className={classes.container}>
      {cards.map(card => (
        <StakeCard key={card.token} {...card} className={classes.card} />
      ))}
    </div>
  );
}
