/** @format */

import React from "react";
import { SiMedium } from "react-icons/si";
import {
  FaReddit,
  FaGithub,
  FaTelegram,
  FaBook,
  FaInstagram,
  FaDiscord,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <div className='container-fluid footer w-100'>
      <div className='container mt-5 pt-5 text-center'>
        <div className='row py-2'>
          <div className='col-md-12'>
            <img
              src={"assets/logo.png"}
              className='w-25 d-block  m-auto'
              alt=''
            />{" "}
            <br />
            <div className='flex justify-center flex-wrap'>
              <a
                rel='noreferrer'
                href='https://dingdang.io/whitepaper.pdf'
                target='_blank'>
                {" "}
                <FaBook className='mx-2 ' />
              </a>
              <a
                href='https://medium.com/@dingadangio'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <SiMedium className='mx-2' />
              </a>
              <a
                href='https://twitter.com/kiktoken'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <FaXTwitter className='mx-2' />
              </a>
              <a
                href='https://t.me/Dingdang_io'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <FaTelegram className='mx-2' />
              </a>
              <a
                href='https://discord.com/invite/gMZfvuhS'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <FaDiscord className='mx-2' />
              </a>
              <a
                href='https://www.reddit.com/r/Dingdangio/'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <FaReddit className='mx-2' />
              </a>
              <a
                href='https://github.com/Dingdangio'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <FaGithub className='mx-2' />
              </a>
              {/* <a href='' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaInstagram className='mx-2' />
              </a> */}
            </div>
            {/* <LangSelect className='text-light m-auto mt-4' /> */}
            <hr className='my-3' />
            <p className='mb-0'>
              © Copyright 2024 - All Rights Reserved - Ding Dang{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
